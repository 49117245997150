import { FC } from "react";
import styles from "../style";
import { apple, avatar1, avatar2, avatar3, googleplay, money } from "../assets";

const Friends: FC = () => {
    return (
        <section className={`w-full bg-[url("./assets/connect_with_friends.svg")] bg-cover bg-center overflow-hidden`}>
            <div className={`${styles.boxWidth} ${styles.flexCenter} mx-auto md:pt-0 pt-[184px]`}>
                <div className={`flex flex-col justify-start items-center bg-[url('./assets/cards.svg')] md:w-[845px] w-[430px] md:h-[900px] h-[430px] bg-cover bg-center md:mb-0 mb-[20px] relative`}>
                    <span className="md:block hidden font-lexend font-normal text-white text-[67px] text-center mt-[60px]">
                        Connect with Friends
                    </span>
                    <div className="flex md:flex-row flex-col md:justify-start justify-center md:items-start items-center md:mt-[114px] -mt-[50px] md:mb-0 mb-[588px]">
                        <div className="flex md:flex-col flex-row justify-start items-start md:space-y-4 md:relative md:-left-[300px] ">
                            <img src={avatar1} className="w-[71px] h-[95px]" />
                            <img src={avatar2} className="w-[71px] h-[95px]" />
                            <img src={avatar3} className="w-[71px] h-[95px]" />
                        </div>
                        <div className="absolute md:top-[260px] top-[120px] md:left-[480px] left-[70px] md:-right-[480px] -right-[96px]">
                            <img src={money} className="md:w-[670px] md:h-[570px] w-[470px] h-[380px]"/>
                        </div>
                    </div>
                    <div className={`md:flex hidden ${styles.flexCenter} mt-[160px] mb-[50px]`}>
                        <img src={googleplay} className="w-[220px] h-[63px]"/>
                        <img src={apple} className="w-[220px] h-[63px]"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Friends;