import React, {FC, FormEvent, useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout, { NavBarStatus } from "../components/Layout";
import styles, { layout } from "../style";
import { CONFIG, RUN_MODE } from "../constants";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [prevPage, setPrevPage] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMessage] = useState('');

    useEffect(()=>{
        console.log("Login useEffect");
        setPrevPage(location.state.prevPage);
      }, []);
      
    const handleSubmit = async (event: FormEvent<HTMLFormElement>) =>
    {
        const param = {email: username, password: password};
        event.preventDefault();
        const response:Response = await fetch(`${CONFIG[RUN_MODE].BACKEND_URL}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        });

        const respData = await response.json();
        if(respData.ret == 0)
        {
            localStorage.setItem("token", respData.token);
            setErrorMessage("");
            navigate(prevPage);
        }else
        {
            setErrorMessage(respData.msg);
        }
        
    };

    return(
        <Layout>
            <div className={`${styles.flexCenter} ${layout.section} bg-main-backcolor`}>
                <div className={`w-full max-w-[513px]`}>                     
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h1 className="font-lexend font-normal text-[46px] text-blood text-center mb-[35px]">Login</h1>
                        <div className="mb-8">
                            <label className="font-lexend font-light text-[18px] block text-gray-700 text-sm font-bold mb-4" htmlFor="username">
                            Username
                            </label>
                            <input 
                            className="shadow appearance-none border border-gray rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-dimPink h-[48px]" 
                            id="username" 
                            type="text" 
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-16">
                            <label className="font-lexend font-light text-[18px] block text-gray-700 text-sm font-bold mb-4" htmlFor="password">
                            Password
                            </label>
                            <input 
                            className="shadow appearance-none border border-gray rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-dimPink h-[48px]" 
                            id="password"
                            type="password"
                            placeholder="******************"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="font-lexend font-light text-[12px] text-blood">{errorMsg}</span>
                        </div>
                        
                        <div className="flex items-center justify-center">
                            <button className="font-lexend font-light h-[70px] rounded-full w-full bg-black hover:bg-blue-700 text-white text-[18px] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default Login;