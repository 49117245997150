// ZohoScript.tsx
import React, { useEffect } from 'react';

declare global {
  interface Window {
    $zoho: any;
  }
}

const ZohoScript: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'zsiqscript';
    script.src = 'https://salesiq.zohopublic.com/widget?wc=siq09ade46d673150c2c1e32d10b6da06131c777295bee4d1f602a5dfa9adacdbee';
    script.defer = true;

    document.body.appendChild(script);

    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      ready: function () {
        window.$zoho.salesiq.visitor.chat();
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default ZohoScript;
