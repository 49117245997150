import React from "react";
import Layout from "../components/Layout";
import styles, { layout } from "../style";

const AccountDeletion: React.FC = () => {

    return (
        <Layout>
            <div className={`${styles.flexCenter} ${layout.section} bg-main-backcolor`}>
                <div className={`w-full max-w-[513px]`}>
                    Account Deletion Request - WhotAfrica
                    <br /><br />
                    
                    Welcome to WhotAfrica's account deletion page. We value your privacy and are committed to
                    making the account deletion process as straightforward as possible. Please follow the steps
                    below to request the deletion of your account and associated data.
                    <br /><br />

                    <b>Steps for Account Deletion</b><br/>
                    <br /><br />
                    Submit a Request:
                    If you wish to delete your WhotAfrica account, please send an email to our support team at
                    <b> admin@whotafrica.com</b> with the subject line "Account Deletion Request."
                    In your email, include the following details:
                    <br /><br />

                    •Your full name<br/>
                    •Your registered email address<br/>
                    •The reason for your request (optional)<br/>

                    <br /><br />
                    <b>Confirmation of Request:</b><br/>
                    Once we receive your request, our support team will review it and may contact you for further
                    verification. This is to ensure that the account deletion request is legitimate and to prevent
                    unauthorized access.
                    <br /><br />
                    <b>Data Deletion Process:</b><br/>
                    Upon successful verification, your account and associated data will be scheduled for deletion.
                    You will receive a confirmation email once the process is complete.
                    <br/><br />
                    <b>Types of Data Deleted</b><br/>
                    When you request the deletion of your WhotAfrica account, the following data will be
                    permanently deleted from our systems:
                    <br/><br />
                    Personal Information: This includes your name, email address, and any other personal details
                    you have provided.
                    <br/><br />
                    Gameplay Data: All in-game progress, scores, and achievements will be erased.
                    <br/><br />
                    Transaction History: Any record of in-app purchases or financial transactions will be deleted.
                    <br/><br />
                    <b>Data Retained and Retention Periods</b><br/>
                    While we strive to delete all your data promptly, certain data may be retained for legal or
                    operational reasons, such as:
                    Financial Records: We are required by law to retain financial transaction records for a specific
                    period. This data will be kept securely and deleted once the retention period expires.

                    Support Requests: If you have contacted our support team in the past, we may retain records of
                    these interactions for quality assurance and legal purposes.
                    <br/><br/>
                    <b>Important Notes</b><br/>
                    Irreversible Action: Please note that account deletion is a permanent action and cannot be
                    undone. Once your account is deleted, all associated data will be permanently erased.
                    Processing Time: Account deletion requests typically take up to 30 days to process. We
                    appreciate your patience during this period.<br/>
                    To access this page in the future or share it with others, you can visit
                    https://www.whotafrica.com/account-deletion.

                </div>
            </div>
        </Layout>
    );
};

export default AccountDeletion;