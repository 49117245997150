import { FC, useState } from "react";
import styles from "../style";
import { keyfeatures } from "../constants";
import UserFriendly from "./UserFriendly";

const KeyFeatures : FC = () => {
    const [active, setActive] = useState("friendly")
    return (
        <section id="services" className="w-full bg-white">
            <p className="font-lexend font-normal text-[67px] md:mt-[70px] mt-[170px] md:mb-0 mb-[100px] text-center w-full">
                Key Features
            </p>

            <section className="md:flex hidden justify-center items-center bg-white border-b border-navBoarderColor mt-[40px]">
                <ul className="flex justify-center items-center">
                    {
                        keyfeatures.map((feature, index)=>(
                            <li className={`flex flex-col items-center font-lexend font-light text-[24px]`} onClick={()=>setActive(feature.id)}>
                                <a href={`#${feature.id}`}  className={`px-[20px] ${index != keyfeatures.length - 1 ? "border-r-2" : ""}`}>{feature.title}</a>
                                <div className={`w-5/6 mt-[20px] ${active == feature.id ? "border-b-4 border-black" : ""}`}></div>
                            </li>
                        ))  
                    }
                </ul>
            </section>

            <UserFriendly />
        </section>
    );
}

export default KeyFeatures;