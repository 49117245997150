import { FC } from "react";
import styles from "../style";
import { apple, googleplay } from "../assets";

const Earning: FC = ()=>{
    return(
        <section className={`flex items-center bg-black h-[121px]`}>
            <div className={`md:flex hidden justify-start items-center ${styles.boxWidth} mx-auto px-[160px]`}>
                <p className="font-lexend font-light text-[34px] text-white">START EARNING NOW</p>
            </div>

            <div className={`md:hidden flex justify-center items-center ${styles.boxWidth} mx-auto`}>
                <img src={googleplay} className="w-[168px] h-[48px]"/>
                <img src={apple} className="w-[168px] h-[48px] rounded-[5px] border border-white"/>
            </div>
        </section>
    );
}

export default Earning;