import { FC } from "react";
import styles from "../style";
import { apple, digital_fusion, friendly_phone_1, friendly_phone_2, game_mode, googleplay, user_friendly_control } from "../assets";

const UserFriendly : FC = () => {
    return(
        <section className="w-full bg-white mb-[60px]">
            <div className={`${styles.boxWidth} grid md:grid-cols-3 grid-cols-1 gap-y-10 gap-x-4 mx-auto md:p-[50px] p-0`}>
                <div className={`${styles.flexStart} md:flex-row flex-col lg:justify-center md:items-end items-center md:col-span-3 bg-lightPink md:p-0 pt-[148px]`}>
                    <div className={`md:flex hidden ${styles.flexStart} flex-col md:ml-[100px] ml-0 md:mt-[220px] md:p-0 p-12`}>
                        <span className="font-lexend font-medium text-[43px] md: ">Immerse</span>
                        <span className="font-lexend font-medium text-[43px]">Yourself in a</span>
                        <div className="flex justify-start items-start">
                            <span className="font-lexend font-medium text-[43px]">Modern</span>
                            <span className="font-lexend font-medium text-[43px] text-blood ml-[10px]">Gaming</span>
                        </div>
                        <span className="font-lexend font-medium text-[43px]">Experience</span>
                        <div className="flex justify-start items-center p-[20px]">
                            <a href=""><img className="w-[161px] h-[46px]" src={googleplay}/></a>
                            <a href=""><img className="w-[161px] h-[46px]" src={apple}/></a>
                        </div>
                    </div>
                    
                    <span className="md:hidden font-lexend font-medium xs:text-[43px] text-[38px] text-center">
                        Immerse Yourself <br/> in a Modern <br/> 
                        <div className="flex justify-center items-center space-x-[12px]">
                            <p className="text-blood">Gaming</p> 
                            <p>Experience</p>
                        </div>
                        
                    </span>
                    
                    <div className={`md:mr-[250px] md:mt-[120px] relative md:left-0 -left-[60px] bottom-0 hidden xs:flex`}>
                        <img src={friendly_phone_1} className="relative z-10 md:w-[422px] w-[260px] md:h-[473px] h-[520px]"/>    
                        <img src={friendly_phone_2} className="left-[220px] bottom-[0px] z-9 absolute" />
                    </div>

                    <div className={`md:mr-[250px] md:mt-[120px] bottom-0 flex flex-center justify-center xs:hidden overflow-hidden`}>
                        <img src={friendly_phone_1} className="md:w-[422px] w-[260px] md:h-[473px] h-[520px]"/>    
                    </div>
                </div>

                <div className={`md:flex hidden ${styles.flexCenter} flex-col bg-lightPink`}>
                    <img src={game_mode} className="mt-[58px]" />
                    <span className="font-lexend font-medium text-[43px] mt-[37px] mb-[78px] w-[271px] h-[108px] text-center">Offline & Online Mode</span>
                </div>

                <div className={`md:flex hidden ${styles.flexCenter} flex-col bg-lightPink`}>
                    <img src={user_friendly_control} className="mt-[58px]" />
                    <span className="font-lexend font-medium text-[43px] mt-[37px] mb-[78px] w-[289px] h-[108px] text-center">User-Friendly Controls</span>
                </div>

                <div className={`md:flex hidden ${styles.flexCenter} flex-col bg-lightPink`}>
                    <img src={digital_fusion} className="mt-[58px]" />
                    <span className="font-lexend font-medium text-[43px] mt-[37px] mb-[78px] w-[313px] h-[108px] text-center">Classic & Digital Fusion</span>
                </div>
            </div>
        </section>
    );
}

export default UserFriendly;