//import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";
export const RUN_MODE = "live";

export const CONFIG = {
    "local" : {
        BACKEND_URL: 'http://localhost:3000/api',
    },
    "dev" : {
        BACKEND_URL: 'https://simplywhot.com/api'
    },
    "live" : {
        BACKEND_URL: 'https://whotafrica.com/api',
    }
}

export const CURRENCY_NGN = 0;
export const CURRENCY_KSH = 1;

export const navLinks = [
    {
        id: "home",
        title: "Home",
    },
    {
        id: "services",
        title: "Services",
    },
    {
        id: "about",
        title: "About",
    },
    {
        id: "basic_info",
        title: "Basic Info",
    },
];

export const stats = [
    {
        id: "stats-1",
        title: "User Active",
        value: "3800+",
    },
    {
        id: "stats-2",
        title: "Trusted by Company",
        value: "230+",
    },
    {
        id: "stats-3",
        title: "Transaction",
        value: "$230M+",
    },
];

export const footerLinks = [
    {
        title: "Useful Links",
        links: [
            {
                name: "Content",
                link: "https://www.hoobank.com/content/",
            },
            {
                name: "How it Works",
                link: "https://www.hoobank.com/how-it-works/",
            },
            {
                name: "Create",
                link: "https://www.hoobank.com/create/",
            },
            {
                name: "Explore",
                link: "https://www.hoobank.com/explore/",
            },
            {
                name: "Terms & Services",
                link: "https://www.hoobank.com/terms-and-services/",
            },
        ],
    },
    {
        title: "Community",
        links: [
            {
                name: "Help Center",
                link: "https://www.hoobank.com/help-center/",
            },
            {
                name: "Partners",
                link: "https://www.hoobank.com/partners/",
            },
            {
                name: "Suggestions",
                link: "https://www.hoobank.com/suggestions/",
            },
            {
                name: "Blog",
                link: "https://www.hoobank.com/blog/",
            },
            {
                name: "Newsletters",
                link: "https://www.hoobank.com/newsletters/",
            },
        ],
    },
    {
        title: "Partner",
        links: [
            {
                name: "Our Partner",
                link: "https://www.hoobank.com/our-partner/",
            },
            {
                name: "Become a Partner",
                link: "https://www.hoobank.com/become-a-partner/",
            },
        ],
    },
];

  
export const socialMedia = [
    {
        id: "social-media-1",
        // icon: instagram,
        link: "https://www.instagram.com/",
    },
    {
        id: "social-media-2",
        // icon: facebook,
        link: "https://www.facebook.com/",
    },
    {
        id: "social-media-3",
        // icon: twitter,
        link: "https://www.twitter.com/",
    },
    {
        id: "social-media-4",
        // icon: linkedin,
        link: "https://www.linkedin.com/",
    },
  ];
  
export const clients = [
    {
        id: "client-1",
        // logo: airbnb,
    },
    {
        id: "client-2",
        // logo: binance,
    },
    {
        id: "client-3",
        // logo: coinbase,
    },
    {
        id: "client-4",
        // logo: dropbox,
    },
];

export const keyfeatures = [
    {
        id: "friendly",
        title: "USER FRIENDLY"
    },
    {
        id: "interface",
        title: "SEAMLESS INTERFACE"
    },
    {
        id: "navigation",
        title: "SLEEK NAVIGATION"
    },
    {
        id: "prize",
        title: "CASH PRIZES"
    },
];
