import React, { useEffect } from "react";
import Layout from "../components/Layout";
import styles, { layout } from "../style";
import { useNavigate } from "react-router-dom";
import { logo } from "../assets";

const Terms: React.FC = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        window.scrollTo(0,0);
    }, []);
    return (
        <div className="w-full">
            <div className={`sticky top-0 z-40 w-full ${styles.flexCenter} border-b border-navBorderColor bg-white`}>
                <div className={`${styles.boxWidth} ${styles.paddingX} mx-auto`}>
                    <nav className="w-full flex py-6 justify-between items-center navbar h-[85px]">
                        <div className={`flex-1 w-[110px] h-[28px]`} onClick={() => { navigate("/"); }}>
                            <img src={logo} alt="whot" />
                        </div>
                    </nav>
                </div>
            </div>
            <div className={`${styles.flexCenter} ${layout.section} bg-main-backcolor`}>
                <div id="terms_head" className={`w-full max-w-[600px] p-5`}>
                    <span id="top_title" className="font-bold text-3xl">Whot Africa Terms and Conditions</span>
                    <br /><br />
                    <b className="text-xl">1. Introduction</b>
                    <br /><br />
                    Welcome to Whot Africa! These Terms and Conditions ("Terms") govern your use of the Whot Africa mobile gaming application ("App") and the Whot Africa website (“Website”) and any services provided by Whot Africa ("We," "Us," or "Our"). By downloading, installing, or using the App, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use the App.
                    <br /><br />

                    <b className="text-xl">2. Eligibility</b>
                    <br /><br />
                    By using Whot Africa, you represent and warrant that you are at least 18 years old and have the legal capacity to enter into this agreement. If you are under 18 years of age, you must not use the App.
                    <br /><br />

                    <b className="text-xl">3. Account Registration</b>
                    <br /><br />
                    To access and use certain features of the App, you must create an account. You agree to provide accurate and complete information during registration and to keep this information up-to-date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                    <br /><br />

                    <b className="text-xl">4. Game Play and Rules</b>
                    <br /><br />
                    Whot Africa allows users to play online Whot games with other players. The rules for each game are available within the App and may be updated from time to time. You agree to comply with all rules and instructions provided in the App.
                    <br /><br />

                    <b>4.1. Staking Money</b>
                    <br /><br />
                    You may stake money in certain game modes. By participating in these games, you acknowledge that you are staking real money and that you may lose the amount staked. All stakes are final and non-refundable.
                    <br /><br />

                    <b>4.2. Prizes</b>
                    <br /><br />
                    Winning a game may entitle you to a prize. The value and type of prize will depend on the specific game mode. Prizes are credited to your in-app wallet and can be withdrawn or used to stake in future games. We reserve the right to withhold prizes if we suspect any fraudulent activity or violation of these Terms.
                    <br /><br />

                    <b className="text-xl">5. Wallets and Transactions</b>
                    <br /><br />
                    Whot Africa provides an in-app wallet system for managing your funds. You agree that:
                    <br /><br />
                    •	Deposits: You can deposit funds into your wallet using various payment methods provided in the App. Deposits are processed through third-party payment processors, and we are not responsible for any errors or delays.
                    <br />
                    •	Withdrawals: You may withdraw available funds from your wallet to your designated bank account. Withdrawals are subject to processing times and any applicable fees as outlined in the App.
                    <br />
                    •	Transaction Limits: We may impose limits on the amount you can deposit, withdraw, or hold in your wallet. These limits may vary based on your account activity and verification status.
                    <br /><br />

                    <b className="text-xl">6. Fees</b>
                    <br /><br />
                    Whot Africa may charge fees for certain services, including but not limited to deposits, withdrawals, and game entry fees. All applicable fees will be clearly disclosed in the App before you complete a transaction.
                    <br /><br />

                    <b className="text-xl">7. User Conduct</b>
                    <br /><br />
                    You agree not to engage in any of the following prohibited activities:
                    <br /><br />
                    •	Cheating: Using any form of unauthorized software, tools, or exploits to gain an unfair advantage in the game.
                    <br />
                    •	Harassment: Engaging in any behavior that is abusive, threatening, or offensive to other users.
                    <br />
                    •	Fraud: Attempting to defraud Whot Africa or other users, including using stolen payment methods or attempting to manipulate the outcome of games.
                    <br />
                    •	Multiple Accounts: Creating multiple accounts to gain unfair advantages or circumvent restrictions.
                    <br /><br />
                    Violations of this section may result in the suspension or termination of your account and forfeiture of any prizes or funds.
                    <br /><br />

                    <b className="text-xl">8. Cookies</b>
                    <br /><br />
                    We employ the use of cookies. By accessing Whot Africa Digital Limited, you agree to use cookies in agreement with Whot Africa Digital Limited's Privacy Policy. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                    <br /><br />

                    <b className="text-xl">9. Intellectual Property</b>
                    <br /><br />
                    All content, including but not limited to text, graphics, logos, and software, within the App is the property of Whot Africa or its licensors and is protected by copyright and other intellectual property laws. You are granted a limited, non-exclusive, non-transferable license to use the App for personal, non-commercial purposes. You may not copy, modify, distribute, or reverse engineer any part of the App without our prior written consent.
                    <br /><br />
                    <b>Hyperlinking to our Content</b>
                    <br /><br />
                    The following organizations may link to our Website without prior written approval:
                    <br /><br />
                    •	Government agencies;
                    <br />
                    •	Search engines;
                    <br />
                    •	News organisations;
                    <br />
                    •	Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
                    <br />
                    •	System-wide Accredited Businesses except soliciting non-profit organizations, shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                    <br /><br />
                    These organisations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
                    <br /><br />
                    We may consider and approve other link requests from the following types of organisations:
                    <br /><br />
                    •	commonly-known consumer and/or business information sources;
                    <br />
                    •	dot.com community sites;
                    <br />
                    •	associations or other groups representing charities;
                    <br />
                    •	online directory distributors;
                    <br />
                    •	internet portals;
                    <br />
                    •	accounting, law and consulting firms; and
                    <br />
                    •	educational institutions and trade associations.
                    <br /><br />
                    We will approve link requests from these organisations if we decide that: (a) the link would not make us look unfavourably to ourselves or our accredited businesses; (b) the organisation does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Whot Africa Digital Limited; and (d) the link is in the context of general resource information.
                    <br /><br />
                    These organisations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
                    <br /><br />
                    If you are one of the organisations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to admin@whotafrica.com. Please include your name, your organisation name, and contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 3-4 weeks for a response.
                    <br /><br />
                    Approved organisations may hyperlink to our Website as follows:
                    <br /><br />
                    •	By use of our corporate name; or
                    <br />
                    •	By use of the uniform resource locator being linked to; or
                    <br />
                    •	By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.
                    <br /><br />
                    No use of Whot Africa Digital Limited logo or other artwork will be allowed for linking absent a trademark license agreement.
                    <br /><br />

                    <b>iFrames</b>
                    <br /><br />
                    Without prior approval and written permission, you may not create frames around our web pages that alter in any way the visual presentation or appearance of our Website.
                    <br /><br />

                    <b>Content Liability</b>
                    <br /><br />
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third-party rights.
                    <br /><br />

                    <b>Reservation of Rights</b>
                    <br /><br />
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                    <br /><br />

                    <b>Removal of links from our website</b>
                    <br /><br />
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
                    <br /><br />
                    We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                    <br /><br />

                    <b className="text-xl">10. Privacy</b>
                    <br /><br />
                    Your use of the App is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using the App, you agree to the terms of our Privacy Policy.
                    <br /><br />

                    <b className="text-xl">11. Disclaimers and Limitation of Liability</b>
                    <br /><br />

                    <b>11.1. Disclaimers</b>
                    <br /><br />
                    Whot Africa is provided "as is" and "as available," without any warranties, express or implied. We do not guarantee that the App will be error-free, secure, or uninterrupted, or that any defects will be corrected. We do not warrant the accuracy or reliability of any information or content within the App.
                    <br /><br />

                    <b>11.2. Limitation of Liability</b>
                    <br /><br />
                    To the fullest extent permitted by law, Whot Africa, its affiliates, and its licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, or goodwill, arising out of or related to your use of the App.
                    <br /><br />

                    <b className="text-xl">12. Indemnification</b>
                    <br /><br />
                    You agree to indemnify and hold harmless Whot Africa, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of or related to your use of the App, your violation of these Terms, or your violation of any third-party rights.
                    <br /><br />

                    <b className="text-xl">13. Modifications to the Terms</b>
                    <br /><br />
                    We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting within the App. Your continued use of the App after the changes have been posted constitutes your acceptance of the modified Terms. It is your responsibility to review these Terms periodically.
                    <br /><br />

                    <b className="text-xl">14. Termination</b>
                    <br /><br />
                    We may terminate or suspend your account and access to the App at our sole discretion, without prior notice, for any reason, including but not limited to your breach of these Terms. Upon termination, you must cease all use of the App and forfeit any remaining funds or prizes in your account.
                    <br /><br />

                    <b className="text-xl">15. Governing Law and Dispute Resolution</b>
                    <br /><br />
                    These Terms are governed by the laws of the Federal Republic of Nigeria and the High Court (Federal or State) sitting in the FCT shall have and exercise jurisdiction in any causes or matters arising therefrom.
                    <br /><br />

                    <b className="text-xl">16. Severability</b>
                    <br /><br />
                    If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect.
                    <br /><br />

                    <b className="text-xl">17. Entire Agreement</b>
                    <br /><br />
                    These Terms, together with our Privacy Policy and any other legal notices published by Whot Africa, constitute the entire agreement between you and Whot Africa concerning your use of the App and Website.
                    <br /><br />

                    <b className="text-xl">18. Contact Information</b>
                    <br /><br />
                    If you have any questions or concerns regarding these Terms, please contact us at admin@whotafrica.com.
                    <br /><br />
                </div>
            </div>
        </div>
    );
};

export default Terms;