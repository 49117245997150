import React, { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout, { NavBarStatus } from "../components/Layout";
import styles from "../style";
import { UserInfo, decryptToken } from "../service/auth";
import { CONFIG, RUN_MODE } from "../constants";

const ChatBox: React.FC = () => {
    useEffect(() => {
        // set the inline-mode of the chatbox to true

    }, []);


    return (
        <chat-widget id="sprout-chat-widget" class="flex flex-row justify-center items-center w-full h-screen" location-id="BeqxdqZ6o5UijS5DHGMh" show-prompt={false} inline-mode={true}>
        </chat-widget>


    );
};

export default ChatBox;