import React, { FC, FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout, { NavBarStatus } from "../components/Layout";
import styles, { layout } from "../style";
import { UserInfo, decryptToken } from "../service/auth";
import { CONFIG, RUN_MODE } from "../constants";
import { useSearchParams } from "react-router-dom";
import { verify } from "crypto";
import { success, fail } from "../assets";

const ResetPasswordForm: React.FC = () => {
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            const params = {
                token, password
            };

            const response = await fetch(`${CONFIG[RUN_MODE].BACKEND_URL}/reset-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(params)
            })

            const responseData = await response.json();
            setMessage(responseData.message);
        } catch (error) {
            setMessage('Error resetting password. Please try again.');
        }
    };

    return (
        <Layout>
            <div className={`${styles.flexCenter} ${layout.section} bg-main-backcolor`}>
                <div className={`w-full max-w-[513px]`}>
                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <h1 className="font-lexend font-normal text-[46px] text-blood text-center mb-[35px]">Reset Password</h1>
                        <div className="mb-16">
                            <label className="font-lexend font-light text-[18px] block text-gray-700 text-sm font-bold mb-4" htmlFor="password">
                                New Password
                            </label>
                            <input
                                className="shadow appearance-none border border-gray rounded-full w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-dimPink h-[48px]"
                                id="password"
                                type="password"
                                placeholder="******************"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="font-lexend font-light text-[12px] text-blood">{message}</span>
                        </div>

                        <div className="flex items-center justify-center">
                            <button className="font-lexend font-light h-[70px] rounded-full w-full bg-black hover:bg-blue-700 text-white text-[18px] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default ResetPasswordForm;