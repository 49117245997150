import { FC } from "react";
import styles from "../style";
import { flat_phone_1, flat_phone_2, flat_phone_3, flat_phone_4 } from "../assets";

const FlatPart: FC = ()=>{
    return (
        <section id="about" className="w-full bg-flatPart">
            <div className={`${styles.boxWidth} ${styles.flexCenter} md:flex-row flex-col mx-auto`}>
                <img src={flat_phone_1} className="md:block hidden w-[277px] h-[575px] md:mt-[97px] mt-[30px] md:mb-[132px]"/>
                <img src={flat_phone_2} className="w-[277px] h-[575px] md:mt-[132px] mt-[30px]"/>
                <img src={flat_phone_3} className="md:block hidden w-[277px] h-[575px] md:mt-[97px] md:mb-[132px]"/>
                <img src={flat_phone_4} className="md:block hidden w-[277px] h-[575px] md:mt-[132px]"/>
            </div>
        </section>
    );
};

export default FlatPart;