import React, { FC, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import PrivateRoutes from './components/PrivateRoutes';
import Deposit from './pages/Deposit';
import Verify from './pages/Verify';
import ResetPasswordForm from './pages/ResetPassword';
import ChatBox from './pages/ChatBox';
import AccountDeletion from './pages/AccountDeletion';
import MarasoftPayResult from './pages/MarasoftPayResult';
import CredoVerify from './pages/CredoVerify';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import PayazaResult from './pages/PayazaResult';

const App: FC = () => {
  return (
    <Routes>
      <Route path = "/" element = {<Home/>} />
      <Route path = "/login" element = {<Login/>} />
      <Route path = "/deposit" element = {<Deposit/>} />
      <Route path = "/verify" element = {<Verify/>} />
      <Route path = "/chat" element = {<ChatBox/>} />
      <Route path = "/reset-password/:token" element = {<ResetPasswordForm/>} />
      <Route path = "/account-deletion" element = {<AccountDeletion/>} />
      <Route path = "/marasoftpay-result" element = {<MarasoftPayResult/>} />
      <Route path = "/payaza-result" element = {<PayazaResult/>} />
      <Route path = "/credo-verify" element = {<CredoVerify/>} />
      <Route path = "/privacy-policy" element = {<PrivacyPolicy/>} />
      <Route path = "/terms" element = {<Terms/>} />
    </Routes>
  );
}

export default App;