import { FC } from "react";
import styles from "../style";
import { avatar, cardnum10, emoticon, hero, playername, qrcode, score1, score5, whotback } from "../assets";

const Hero: FC = () => {
    return (
        <section id="home" className="flex justify-center bg-home mt-[79px]">
            <div className={`w-full flex justify-center md:flex-row flex-col items-center ${styles.boxWidth} md:space-x-[100px]`}>
                <div className={`${styles.flexStart} items-center flex-col`}>
                    <div className="font-adineue text-white text-[96px] md:mt-0 md:mb-0 mt-[160px] mb-[120px]">Play to Win</div>
                    <div className="md:flex justify-start hidden">
                        <img src={qrcode} />    
                        <span className="font-lexend font-light text-white text-[18px] w-[287px] p-[20px]">SCAN THE QR CODE TO DOWNLOAD THE APP FOR ANDROID OR IOS</span>
                    </div>   
                </div>

                <div className={`${styles.flexStart} relative`}>
                    <img src={hero} className="mt-[30px] w-[405px] h-[505px] opacity-1" loading="lazy"/>
                    <div></div>               
                    <div className="flex items-center absolute bottom-[60px] left-[0px] z-[40px]">
                        <div className="flex flex-col items-center">
                            <img src={score5} className="w-[18px] h-[27px]" />
                            <img src={score1} className="w-[21px] h-[27px] mt-[10px]"/>
                            <img src={whotback} className="w-[60px] h-[72px] mt-[10px]" />
                            <img src={avatar} className="w-[63px] h-[63px] top-[98px] absolute" />
                            <img src={cardnum10} className="w-[20px] h-[20px] top-[82px] left-[50px] absolute" />

                            <img src={playername} className="w-[69px] h-[25px] mt-[20px]" />
                        </div>
                        
                        <div className="ml-[20px] mt-[60px]">
                            <button type="button" className="flex justify-center w-[137px] h-[66px] bg-white rounded-full">
                                <img src={emoticon} className="w-[69px] h-[25px] mt-[20px]" />
                            </button>
                        </div>
                    </div>
                </div>

                
            </div>
        </section>
    );
}

export default Hero;